import * as React from "react"
import { graphql } from "gatsby"
import ReadingIndicator from "../components/reading_indicator"

import "./blog-post.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  const { image, title, seotitle, date } = post.frontmatter
  const imagePath = image && image.childImageSharp.fixed.src
  const final_title = seotitle ? seotitle : title

  const article = React.createRef<HTMLElement>()

  return (
    <>
      <ReadingIndicator target={article} />
      <Layout>
        <Seo
          title={final_title}
          description={post.frontmatter.description || post.excerpt}
          image={imagePath}
        />
        <article
          ref={article}
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
          </header>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
        <hr style={{ margin: "36px 0" }} />
        <div className="group space-between row-mobile-col">
          <div>
            <p
              style={{
                color: "var(--text-light)",
                textTransform: "uppercase",
                fontSize: "var(--size-0)",
                marginBottom: "8px",
              }}
            >
              Skrevet af
            </p>
            <p style={{ marginBottom: "12px" }}>
              Torben Holmelund & Nikolaj Gilstrøm
            </p>
          </div>
          <div>
            <p
              style={{
                color: "var(--text-light)",
                textTransform: "uppercase",
                fontSize: "var(--size-0)",
                marginBottom: "8px",
              }}
            >
              Udgivet
            </p>
            <p style={{ marginBottom: "12px" }}>{date}</p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD/MM, YYYY")
        seotitle
        image {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
